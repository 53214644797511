@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  img {
    @apply inline-block;
  }
}
html {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
body {
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Switzer", sans-serif;
}

video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
/*  slider range, custom volume */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.2s ease;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: rgb(56 189 248);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.1);
  background: rgb(14 165 233);
}

input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: rgb(56 189 248);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

input[type="range"]::-moz-range-thumb:hover {
  transform: scale(1.1);
  background: rgb(14 165 233);
}
:root {
  --toastify-color-success: #38bdf8;
  --toastify-color-error: #f87171;

  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-error: var(--toastify-color-error);
}
.will-change-transform {
  will-change: transform;
}

.cursor::after {
  display: block;
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: #fff;
  animation: cursor 0.6s linear infinite alternate;
  will-change: opacity;
}

@keyframes cursor {
  0%,
  40% {
    opacity: 1;
  }

  60%,
  100% {
    opacity: 0;
  }
}

.focus::after {
  content: "";
  position: absolute;
  left: 90px;
  width: 40%;
  height: 15px;
  bottom: 25px;
  background: url("assets/vector.svg") no-repeat left top;
  background-size: 100% auto;
  color: #38bdf8;
}
#curve {
  fill: transparent;
}

#curveText {
  fill: white;
  font-size: 20px;
}
.input {
  height: 55px;
  width: 450px;
}

.pomoIcon {
  transform: translateY(-360%);
}

.titleYoutube {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
